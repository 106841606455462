import { useEffect, useRef, useState } from "react";
import PlayPause from "@components/PlayPause.tsx";
import attribute from "@/utils/attribute.ts";
import hexToRgba from "@/utils/hexToRgba.ts";
import Scheduler from "@components/gems/Scheduler.tsx";
import Embed from "./gems/Embed";
import { Gem, PageData } from "@/gem.types";
import { hc } from "hono/client";
import { AppType } from "../../../packages/functions/src/gems/api/public";
import ImageGemElement from "./elements/gem/ImageGemElement";
import TextGemElement from "./elements/gem/TextGemElement";
import VideoGemElement from "./elements/gem/VideoGemElement";
import VideoViewer from "./VideoViewer";

interface Props {
  apis: Record<string, string>;
  parentGemId: string;
  parentUserId: string;
  parentGemName: string;
  gemEndpoint: string;
  element: {
    id: string;
    gem: {
      duration?: number;
      eventType?: string;
      text: string;
      userId: string;
      gemId: string;
      name: string;
      type: Gem["type"];
      url: string;
      image: string;
      ogImage: string;
      shortId: string;
      playbackId: string;
      optimized: string;
      uri: string;
    };
  };
  contactUrl: {
    contactId: string;
    userId: string;
  };
  theme: PageData["theme"];
  attribution?: string;
  mediaEndpoint: string;
  calendarEndpoint: string;
  isVideo?: boolean;
  index?: number;
  onChangeScene?: (gemId: string) => void;
}

const GemElement = ({
  parentGemId,
  parentUserId,
  parentGemName,
  gemEndpoint,
  element,
  contactUrl,
  theme,
  attribution,
  mediaEndpoint,
  calendarEndpoint,
  isVideo,
  index,
  onChangeScene,
  apis,
}: Props) => {
  const [playerState, setPlayerState] = useState<"playing" | "paused">(
    "paused",
  );
  const imageRef = useRef<HTMLImageElement>(null);
  const [viewerOpen, setViewerOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const gem = element.gem as any;

  let url: string = "#";
  let image: string = "";

  useEffect(() => {
    if (viewerOpen) {
      window.dispatchEvent(
        new CustomEvent("viewerStateChange", {
          detail: { active: true, index },
        }),
      );
    } else {
      window.dispatchEvent(
        new CustomEvent("viewerStateChange", {
          detail: { active: false, index },
        }),
      );
    }
  }, [viewerOpen]);

  if (gem) {
    if (gem.type === "video") {
      image = `https://image.mux.com/${gem.playbackId}/animated.gif`;
    }

    if (gem.type === "image" && gem.image) {
      image = `${mediaEndpoint}/${gem?.optimized || gem.image}`;
    }

    if (gem.type === "link") {
      image = gem.image as string;
    }

    if (gem.type === "product") {
      image = gem.uri as string;
    }
  }

  if (gem?.type === "link" || gem?.type === "product") {
    url = attribute(gem.url, attribution || "") || "#";
  } else {
    url = `/${gem?.shortId}?ref=${gem?.gemId}`;
  }

  const handlePlayPauseClick = () => {
    setPlayerState("playing");
    setTimeout(() => {
      setPlayerState("paused");
    }, 1000);
  };

  if (!gem) return null;

  const handleGemClick = () => {
    if (["video", "embed"].includes(gem?.type)) {
      setViewerOpen(true);
      setIframeUrl(url);
    } else {
      if (gem.type === "image") {
        window.open(image, "_blank");
      }
      window.location.href = url;
    }
  };

  const renderPreview = () => {
    if (gem.type === "image") {
      return null;
    }

    if (gem.type === "product") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const imageToRender = gem.ogImage || gem.uri;
      if (!imageToRender) {
        return (
          <div
            className="mb-2 font-semibold cursor-pointer"
            style={{ color: theme.containerTextColor }}
          >
            {gem.name}
          </div>
        );
      }
    }

    return (
      <>
        {!["video", "image"].includes(gem.type) && (
          <h1
            className="mb-6 text-3xl font-bold text-center w-full leading-tight text-black"
            style={{ color: theme?.containerTextColor }}
          >
            {gem.name}
          </h1>
        )}
        <div
          className="relative w-full flex items-center justify-center"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: ["video"].includes(gem.type) ? "contain" : "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: ["video"].includes(gem.type)
              ? "#000"
              : theme.backgroundColor,
            height: window.innerHeight * 0.35,
            cursor: gem.type === "image" ? "pointer" : "default",
            maxHeight: "70vh",
          }}
        >
          <img ref={imageRef} src={image} alt="" style={{ display: "none" }} />
          {!["video", "embed"].includes(gem.type) ? null : (
            <PlayPause
              state={playerState}
              onClick={handlePlayPauseClick}
              height={160}
              width={160}
            />
          )}
        </div>
      </>
    );
  };

  if (
    gem?.type === "link" ||
    gem?.type === "page" ||
    gem.type === "pdf" ||
    isVideo
  ) {
    let title = gem.name;

    if (gem?.type === "product") {
      title = `${gem.name}`;
    }

    if (gem?.type === "embed" || gem?.type === "video") {
      title = `${gem.name}`;
    }

    if (gem?.type === "scheduler") {
      title = `${gem.name}`;
    }

    const sendActivity = async (element: any) => {
      const client = hc<AppType>(gemEndpoint, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!contactUrl) return;
      try {
        await client.public.activity.$post({
          json: {
            gemId: parentGemId,
            userId: contactUrl.contactId,
            gemName: parentGemName,
            activityType: "button",
            elementName: element.text,
            contactId: contactUrl.contactId,
          },
        });
      } catch (error) {
        console.error("Error logging activity:", error);
      }
    };

    const handleButtonClick = async () => {
      await sendActivity({
        id: element.id,
        text: gem.name,
      });
      if (gem?.type === "video" && onChangeScene) {
        onChangeScene(gem.shortId);
      } else {
        window.location.href = url;
      }
    };

    return (
      <button
        onClick={handleButtonClick}
        className="relative flex items-center justify-center w-full py-2 px-4 mb-6 bg-white  border-2 rounded-full transition-transform transform hover:scale-105"
        style={{
          borderColor: theme?.primaryColor,
          backgroundColor: hexToRgba(theme?.backgroundColor || "", 0.8),
          zIndex: 1,
        }}
      >
        <span
          className="text-lg font-semibold"
          style={{ color: theme?.textColor }}
        >
          {title}
        </span>
      </button>
    );
  }

  if (gem.type === "text") {
    return <TextGemElement element={element} theme={theme} />;
  }

  if (gem.type === "scheduler") {
    return (
      <div>
        <Scheduler apis={apis} gem={gem} inPage={true} theme={theme} />
      </div>
    );
  }

  if (gem.type === "image") {
    return (
      <ImageGemElement
        element={element}
        theme={theme}
        mediaEndpoint={mediaEndpoint}
      />
    );
  }

  const renderViewer = () => {
    return (
      <VideoViewer
        isOpen={viewerOpen}
        onClose={() => setViewerOpen(false)}
        url={iframeUrl || ""}
        theme={theme}
        gem={gem}
        contactUrl={contactUrl}
        gemEndpoint={gemEndpoint}
      />
    );
  };

  if (["video"].includes(gem.type)) {
    return (
      <VideoGemElement
        element={element}
        theme={theme}
        gemEndpoint={gemEndpoint}
        contactUrl={contactUrl}
      />
    );
  }

  if (gem.type === "embed") {
    return (
      <div
        className="transition-all duration-300 ease-in-out w-full mb-1 h-21"
        style={{
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        }}
      >
        <Embed gem={gem} />
      </div>
    );
  }

  return (
    <>
      <div
        className="transition-all duration-300 ease-in-out p-4 w-full px-8 py-6 mb-10 shadow-lg rounded-2xl cursor-pointer z-10"
        style={{
          border: `2px solid ${theme.borderColor}`,
          backgroundColor: theme.containerColor,
          zIndex: 1,
        }}
        onClick={handleGemClick}
      >
        {renderPreview()}
      </div>
      {renderViewer()}
    </>
  );
};

export default GemElement;
